import Vue from 'vue'
import { format } from 'date-fns'
import * as Sentry from '@sentry/browser'

import { LOCALE_NAMES } from './localeOptions'

// global mixins

export default Vue.mixin({
    methods: {
        numberFormat() {
            return this.$store.state.user.user.preferred_number_format
        },

        dateFormatString() {
            const locale = this.$store.state.user.user.preferred_date_format;
            const formatParts = new Intl.DateTimeFormat(locale).formatToParts(new Date());

            return formatParts
                .map(part => {
                    switch (part.type) {
                        case 'day': return 'dd';
                        case 'month': return 'MM';
                        case 'year': return 'yyyy';
                        default: return part.value;
                    }
                })
                .join('');
        },

        displayAsCurrency(value, precision = 0, currencyCode = null) {
            const resolvedCurrencyCode = currencyCode || this.$store.state.user.user.currency_code || 'EUR';

            return new Intl.NumberFormat(this.numberFormat(), {
                style: 'currency',
                currency: resolvedCurrencyCode,
                minimumFractionDigits: precision,
                maximumFractionDigits: precision,
            }).format(value)
        },

        displayAsPercent(value, precision) {
            return new Intl.NumberFormat(this.numberFormat(), {
                style: 'percent',
                minimumFractionDigits: precision || 0,
                maximumFractionDigits: precision || 0,
            }).format(value)
        },

        displayAsNumber(value, precision) {
            return new Intl.NumberFormat(this.numberFormat(), {
                minimumFractionDigits: precision || 0,
                maximumFractionDigits: precision || 0,
            }).format(value)
        },

        displayAsDate(date, showtime = false) {
            if (date.getTime() === date.getTime()) {
                let dateFormat = this.dateFormatString()
                if (showtime) dateFormat = `${dateFormat} HH:mm`

                return format(date, dateFormat)
            }

            return this.$t('§error.invalid_date')
        },

        isFieldRequired(name) {
            if (this.v$[name] === undefined) return ''
            // for the custom requiredIf validators
            if (
                this.v$[name].requiredIf &&
                this.v$[name].requiredIf.$params.type === 'requiredIf'
            ) {
                return this.v$[name].requiredIf.$params.prop() ? '*' : ''
            }

            return this.v$[name].required && this.v$[name].required.$params.type === 'required'
                ? '*'
                : ''
        },
        tableSortableHeadings(sort_by, name) {
            const options = sort_by.reduce((holder, current) => {
                if (current.label === 'oldest' || current.label === 'newest') {
                    return holder
                }
                const optionName = current.label
                    .split('_')
                    .filter((str) => str !== 'ascending' && str !== 'descending')
                    .join('_')

                if (holder[optionName] === undefined) holder[optionName] = []
                holder[optionName].push(current)
                return holder
            }, {})

            return name === undefined ? options : options[name]
        },

        handleShowTableFilters(routeName) {
            if (this.componentsWithFilters.includes(routeName)) {
                this.showFilters({ show: true })
            } else {
                this.showFilters({ show: false })
            }
        },

        $move(route) {
            const fromPath = this.$router.currentRoute.fullPath
            const to =
                typeof route === 'string' || ('path' in route && !('name' in route))
                    ? route
                    : { name: route.name }
            const toPath = this.$router.resolve(to).route.fullPath
            if (fromPath !== toPath) {
                this.$router.push(to)
            }
        },

        clickPath(event) {
            return event.path || event.composedPath()
        },

        clickOutsideAction(event, condition, elements, action) {
            if (condition && !this.clickPath(event).some((el) => elements.includes(el.id))) {
                action()
            }
        },

        hasPermission(permission) {
            return this.$store.state.user.user.permissions.includes(permission)
        },

        isAdmin() {
            return this.hasPermission('admin')
        },

        isOem(name) {
            return name === this.$store.state.user.user.oem
        },

        isWashtec() {
            return this.isOem('washtec')
        },

        billingUnitSingular(name) {
            return this.$t(`§billing_units.${name}-s`)
        },

        billingUnitPlural(name) {
            return this.$t(`§billing_units.${name}-p`)
        },

        billingUnitSingularFromId(id) {
            const { label } = this.$store.state.billingUnit.billingUnitOptions.find(
                (option) => option.value === Number(id)
            )
            return this.billingUnitSingular(label)
        },

        billingUnitPluralFromId(id) {
            if (id === null) {
                Sentry.captureMessage('billingUnitPluralFromId called with null', {
                    level: 'warning',
                })
                return ''
            }

            const { label } = this.$store.state.billingUnit.billingUnitOptions.find(
                (option) => option.value === Number(id)
            ) || { label: '' }

            if (label === '') {
                Sentry.captureMessage(`billingUnitPluralFromId called with invalid ID: ${id}`, {
                    level: 'warning',
                })
                return label
            }

            return this.billingUnitPlural(label)
        },

        billingUnitOptionsPlural(options) {
            return options.map((option) => {
                return {
                    value: option.value,
                    label: this.billingUnitPlural(option.label),
                }
            })
        },
    },
})

/* eslint-disable import/prefer-default-export */
export const setElementOpen = {
    data() {
        return {
            isOpen: false,
        }
    },
    methods: {
        handleClick() {
            if (!this.isLink) {
                this.isOpen = !this.isOpen
            } else if (this.customHandleClick === true) {
                this.$emit('custom-handle-click')
            } else {
                // window.location.pathname = this.link
                this.$move(this.link)
            }
        },

        clickOutside(event) {
            const path = this.clickPath(event)
            if (
                // if path does not include id of the card and card is selected
                !path.some((el) => el.id === this.$el.id) &&
                this.isOpen
            ) {
                this.handleClick()
            }
        },
    },
}

/* eslint-disable import/prefer-default-export */
export const openMailClient = {
    methods: {
        handleClick() {
            const urlencodedNewlines = '%0D%0A%0D%0A'

            const link = `mailto:help@linxfour.com?subject=${encodeURIComponent(
                this.$t('§footer.help_email_draft-subject')
            )}&body=${encodeURIComponent(
                this.$t('§footer.help_email_draft-greeting')
            )}${urlencodedNewlines}${encodeURIComponent(this.$t('§footer.help_email_draft-text'))}`

            window.location.href = link
        },
    },
}

/* eslint-disable import/prefer-default-export */
export const translateCountryMixin = {
    methods: {
        capitalizeWords(input) {
            return input.replace(/\b[a-z]/g, function (letter) {
                return letter.toUpperCase()
            })
        },
        defaultTransformation(name) {
            const capitalized = this.capitalizeWords(name)
            return capitalized.replace(/[^a-zA-Z]+/g, '').substr(0, 8)
        },
        // prettier-ignore
        transformCountyNameToTranslationKey(name) {
            const specialCasesTranslationMap = {
                "Dominican Republic": "DominRep",
                "United States Minor Outlying Islands": "USMinorI",
                "Virgin Islands, British": "BVIsland",
                "Virgin Islands, U.S.": "UVIsland",
                "Côte d'Ivoire": "CteDIvoi",
                "Curaçao": "Curaao",
                "Réunion": "Runion",
                "Åland Islands": "landIsla",
            }

            return (
                specialCasesTranslationMap[name] ||
                this.defaultTransformation(name)
            )
        },
        translateCountryName(countryName) {
            const countryTranslationKey = this.transformCountyNameToTranslationKey(countryName)
            return this.$t(`§country.${countryTranslationKey}`)
        },
    },
}

export const getUserBrowserPreferredLocale = {
    methods: {
        setLocale() {
            this.$i18n.locale = navigator.language.substring(0, 2)
        },
    },
}
