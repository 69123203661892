<template>
    <Calendar
        :class="classes"
        v-model="value"
        :locale="en"
        :showTime="showTime"
        :showIcon="showIcon"
        dateFormat="dd.mm.yy"
        :selectionMode="selectionMode"
        :disabled="disabled"
        :name="name"
    />
</template>

<script>
import Calendar from 'primevue/calendar'

export default {
    name: 'CoreDateInput',

    components: { Calendar },

    props: {
        name: {
            type: String,
            default: '',
        },

        showTime: {
            type: Boolean,
            default: false,
        },

        showIcon: {
            type: Boolean,
            default: true,
        },

        condensed: {
            type: Boolean,
            default: false,
        },

        selectionMode: {
            type: String,
            default: 'single',
        },

        fullWidth: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        validator: {
            type: Object,
            default: null,
        },

        customNotificationMessage: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            en: {
                firstDayOfWeek: 1,
                dayNames: [
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                    'Sunday',
                ],
                dayNamesShort: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                dayNamesMin: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
                monthNames: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December',
                ],
                monthNamesShort: [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec',
                ],
                today: 'Today',
                clear: 'Clear',
                dateFormat: 'dd-mm-yy',
                weekHeader: 'Wk',
            },
        }
    },

    computed: {
        value: {
            get() {
                return this.$attrs.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },

        locale() {
            const { preferred_date_format } = this.$store.state.user.user
            return this[preferred_date_format]
        },

        isValid() {
            if (this.validator !== null && !this.validator.$invalid && this.validator.$dirty) {
                return true
            }

            return false
        },

        isInvalid() {
            if (
                this.customNotificationMessage !== null &&
                this.customNotificationMessage.severity !== undefined &&
                this.customNotificationMessage.severity === 'error'
            ) {
                return true
            }

            if (this.validator !== null && this.validator.$invalid && this.validator.$dirty) {
                return true
            }

            return false
        },

        isWarning() {
            if (
                this.customNotificationMessage !== null &&
                this.customNotificationMessage.severity !== undefined &&
                this.customNotificationMessage.severity === 'warn'
            ) {
                return true
            }

            return false
        },

        classes() {
            return {
                'core-date-input': true,
                'core-date-input--full-width': this.fullWidth,
                'core-date-input--condensed': this.condensed,
                'core-valid': this.isValid,
                'core-invalid': this.isInvalid,
                'core-warning': this.isWarning,
            }
        },
    },
}
</script>

<style lang="scss">
.core-date-input {
    & > .p-inputtext {
        border-right: none;
    }

    &--condensed > .p-inputtext {
        padding: 1.4rem !important;
        font-size: 1.6rem !important;
    }

    & button.p-datepicker-month.p-link {
        border: none;
        color: $cs !important;
        font-weight: 300 !important;
        cursor: pointer;
    }

    & button.p-datepicker-year.p-link {
        border: none;
        color: $cs !important;
        font-weight: 300 !important;
        cursor: pointer;
    }

    & > .p-button.p-button-icon-only.p-datepicker-trigger {
        background: $cn-10;
        border: 1px solid $cn-75;
        border-left: none;
        border-radius: 0 8px 8px 0;
    }

    & > .p-datepicker:not(.p-datepicker-inline) {
        background: $cn-10;
        border: 1px solid $cn-75;
        border-radius: 8px;
    }

    & td.p-datepicker-today > span {
        color: $cp !important;
    }

    & span.p-highlight,
    & td.p-datepicker-today > span.p-highlight {
        background: $cp !important;
        color: $cn-10 !important;
    }

    &--full-width {
        width: 100%;
    }

    &.core-invalid {
        &.p-inputtext.p-component,
        &.p-inputtext.p-component:focus,
        &.p-inputtext.p-component:hover {
            border: 1px solid $color-error;
        }

        & .p-datepicker-trigger.p-button.p-button-icon-only {
            background-color: $color-error;
            border: 1px solid $color-error;
        }
    }
    &.core-warning {
        &.p-inputtext.p-component,
        &.p-inputtext.p-component:focus,
        &.p-inputtext.p-component:hover {
            border: 1px solid $color-warning;
        }

        & .p-datepicker-trigger.p-button.p-button-icon-only {
            background-color: $color-warning;
            border: 1px solid $color-warning;
        }
    }
    &.core-valid {
        &.p-inputtext.p-component,
        &.p-inputtext.p-component:focus,
        &.p-inputtext.p-component:hover {
            border: 1px solid $cp;
        }

        & .p-datepicker-trigger.p-button.p-button-icon-only {
            background-color: $cp;
            border: 1px solid $cp;
        }
    }
}
</style>
