<template>
    <div
        v-if="isNavShown"
        :key="refreshKey"
        class="navigation-menu"
    >
        <Menubar :model="items" />
    </div>
</template>

<script>
import Menubar from 'primevue/menubar'

export default {
    name: 'NavigationMenu',

    components: { Menubar },

    data() {
        return {
            refreshKey: 0,

            items: [],
        }
    },

    computed: {
        isCafVisible() {
            return (
                this.isNavigationGroupShown(['click finance']) &&
                !this.isNavigationGroupShown(['admin'])
            )
        },

        isClientsAndContractVisible() {
            return this.isNavigationGroupShown(['contracts and clients'])
        },

        isEquipmentVisible() {
            return this.isNavigationGroupShown([
                'equipment types',
                'units',
                'iot connectivity',
                'maintenance overview',
                'manage service interval',
                'download maintenance protocol',
                'upload maintenance protocol',
            ])
        },

        isUsersVisible() {
            return this.isNavigationGroupShown(['invite', 'view users', 'edit permissions'])
        },

        isRiskVisible() {
            return this.isNavigationGroupShown(['admin'])
        },

        isAdminVisible() {
            return this.isNavigationGroupShown(['admin'])
        },

        path() {
            return this.$route.fullPath
        },

        isNavShown() {
            return this.path === '/app/home' ? false : true
        },
    },

    watch: {
        '$store.state.user.user.permissions'(newValue) {
            this.items = [
                this.cafItem(),
                this.clientsAndContractItem(),
                this.equipmentItem(),
                this.usersItem(),
                this.riskItem(),
                this.adminItem(),
            ]
        },

        '$route.fullPath'() {
            this.items = [
                this.cafItem(),
                this.clientsAndContractItem(),
                this.equipmentItem(),
                this.usersItem(),
                this.riskItem(),
                this.adminItem(),
            ]
        },
    },

    methods: {
        isCafLinkActive() {
            return this.routeContains(['/app/click-and-finance']) ? 'pi pi-circle-fill' : ''
        },

        cafItem() {
            return {
                label: this.$t('§navigation.click&finance'),
                visible: () => this.isCafVisible,
                icon: this.isCafLinkActive(),

                to: '/app/click-and-finance',
            }
        },

        //

        isClientsAndContractLinkActive() {
            return this.routeContains(['/app/client', '/app/contract']) ? 'pi pi-circle-fill' : ''
        },

        clientsAndContractItem() {
            return {
                label: this.$t('§navigation.clients'),
                visible: () => this.isClientsAndContractVisible,
                icon: this.isClientsAndContractLinkActive(),

                items: [
                    {
                        label: this.$t('§navigation.clients'),
                        to: '/app/client/index',
                    },
                    {
                        label: this.$t('§navigation.contracts'),
                        to: '/app/contract/index',
                    },
                ],
            }
        },

        //

        isEquipmentLinkActive() {
            return this.routeContains([
                '/app/unit-type',
                '/app/unit',
                '/app/token',
                '/app/services',
            ])
                ? 'pi pi-circle-fill'
                : ''
        },

        equipmentItem() {
            return {
                label: this.$t('§navigation.equipment'),
                visible: () => this.isEquipmentVisible,
                icon: this.isEquipmentLinkActive(),

                items: [
                    {
                        label: this.$t('§navigation.unit_types'),
                        visible: () => this.hasPermission('equipment types'),
                        to: '/app/unit-type/index',
                    },
                    {
                        label: this.$t('§navigation.units'),
                        visible: () => this.hasPermission('units'),
                        to: '/app/unit/index',
                    },
                    {
                        label: this.$t('§navigation.iot_connectivity'),
                        visible: () => this.hasPermission('iot connectivity'),
                        to: '/app/token/index',
                    },
                    {
                        label: this.$t('§navigation.manage_service_interval'),
                        visible: () => this.hasPermission('manage service interval'),
                        to: '/app/services/create',
                    },
                    {
                        label: this.$t('§navigation.upload_protocols'),
                        visible: () => this.hasPermission('upload maintenance protocol'),
                        to: '/app/services/upload-protocols',
                    },
                    {
                        label: this.$t('§navigation.maintenance_overview'),
                        visible: () => this.hasPermission('maintenance overview'),
                        to: '/app/services/overview',
                    },
                ],
            }
        },

        //

        isUsersLinkActive() {
            return this.routeContains(['/app/users']) ? 'pi pi-circle-fill' : ''
        },

        usersItem() {
            return {
                label: this.$t('§navigation.users'),
                visible: () => this.isUsersVisible,
                icon: this.isUsersLinkActive(),

                items: [
                    {
                        label: this.$t('§navigation.view_all_users'),
                        visible: () => this.hasPermission('view users'),
                        to: '/app/users/index',
                    },
                    {
                        label: this.$t('§navigation.user_permissions'),
                        visible: () => this.hasPermission('edit permissions'),
                        to: '/app/users/permissions',
                    },
                    {
                        label: this.$t('§navigation.my_user_invites'),
                        visible: () => this.hasPermission('invite'),
                        to: '/app/users/invites-index',
                    },
                    {
                        label: this.$t('§navigation.invite_user'),
                        visible: () => this.hasPermission('invite'),
                        to: '/app/users/invite',
                    },
                ],
            }
        },

        //

        isRiskLinkActive() {
            return this.routeContains([
                '/app/contract-admin-approval',
                '/app/risk-management',
                '/app/invoice',
            ])
                ? 'pi pi-circle-fill'
                : ''
        },

        riskItem() {
            return {
                label: this.$t('§navigation.operations_and_risk'),
                visible: () => this.isRiskVisible,
                icon: this.isRiskLinkActive(),

                items: [
                    {
                        label: this.$t('§navigation.contract_approval'),
                        to: '/app/contract-admin-approval/index',
                    },
                    {
                        label: this.$t('§navigation.risk_management'),
                        to: '/app/risk-management/risk-management-dashboard',
                    },
                    {
                        label: this.$t('§navigation.invoice_management'),
                        to: '/app/invoices/index',
                    },
                    {
                        label: this.$t('§navigation.operations_management'),
                        to: '/app/operations-management/missing-contract-documents-overview',
                    },
                    {
                        label: this.$t('§navigation.export_live_data'),
                        command: () => window.open('/export/live', '_blank'),
                    },
                    {
                        label: this.$t('§navigation.export_insurance_data'),
                        command: () => window.open('/export/insurance', '_blank'),
                    },
                ],
            }
        },

        //

        isAdminLinkActive() {
            return this.routeContains(['/app/admin', '/app/industry', '/app/product-type'])
                ? 'pi pi-circle-fill'
                : ''
        },

        adminItem() {
            return {
                label: this.$t('§navigation.settings'),
                visible: () => this.isAdminVisible,
                icon: this.isAdminLinkActive(),

                items: [
                    {
                        label: this.$t('§navigation.caf_settings'),
                        to: '/app/admin/caf-settings',
                    },
                    {
                        label: this.$t('§navigation.countries'),
                        to: '/app/admin/country-index',
                    },
                    {
                        label: this.$t('§navigation.industries'),
                        to: '/app/admin/industry-index',
                    },
                    {
                        label: this.$t('§navigation.invoice_settings'),
                        to: '/app/admin/invoice-settings',
                    },
                    {
                        label: this.$t('§navigation.billing_units'),
                        to: '/app/admin/billing-units-index',
                    },
                    {
                        label: this.$t('§navigation.emails_and_compliance'),
                        to: '/app/admin/emails-compliance-settings',
                    },
                    {
                        label: this.$t('§navigation.market_data'),
                        command: () => window.open('/admin/swap_rates/form', '_blank'),
                    },
                    {
                        label: this.$t('§navigation.translations'),
                        command: () => window.open('/translations', '_blank'),
                    },
                ],
            }
        },

        //

        isNavigationGroupShown(submenuPermissionsList) {
            return submenuPermissionsList.some((permissionName) => {
                return this.$store.state.user.user.permissions.includes(permissionName)
            })
        },

        routeContains(pathsArray) {
            const contained = pathsArray.some((partialPath) => {
                return window.location.pathname.includes(partialPath)
            })

            return contained ? 'pi pi-circle-fill' : ''
        },
    },
}
</script>

<style lang="scss">
.navigation-menu {
    & > .p-menubar.p-component {
        border: none;

        & > ul > li {
            margin: 0 20px;
        }

        & .p-submenu-icon.pi.pi-angle-down {
            margin-left: 12px;
        }

        & .p-submenu-list {
            margin-top: 12px;
            padding: 12px 20px;
        }

        & .p-submenu-list {
            width: 220px;
            border-radius: 4px;
        }

        & .p-submenu-list .p-menuitem {
            margin: 16px 0;

            & > .router-link-active.p-menuitem-link > .p-menuitem-text {
                color: $cs-25;
            }

            &:hover > a.p-menuitem-link > .p-menuitem-text {
                color: $cs-25;
            }
        }

        & .p-menuitem-icon.pi.pi-circle-fill {
            font-size: 10px;
            color: $cp;
            margin-right: 8px;
        }
    }

    & > .p-menubar.p-component.p-menubar-mobile-active > .p-menubar-root-list {
        width: 260px;
        padding: 20px;

        & > .p-menuitem {
            margin: 12px 0;
        }
    }
}
</style>
