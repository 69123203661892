import AuthorizedPersonModal from '@/views/Client/AuthorizedPersonModal.vue' //
import ClientItemModal from '@/views/Client/ClientItemModal.vue' //
import UnitTypeItemModal from '@/views/UnitType/UnitTypeItemModal.vue' //
// import ContractUploadModal from '@/views/Contract/ContractUploadModal.vue' //
import UploadContractFileModal from '@/views/Contract/UploadContractFileModal.vue' //
import InvoiceUploadModal from '@/views/Invoices/InvoiceUploadModal.vue' //
import ContractCreateModal from '@/views/Contract/ContractCreateModal/ContractCreateModal.vue' //
import SelectUnitTypeModal from '@/views/ClickAndFinance/modals/SelectUnitTypeModal.vue' //
import SelectClientModal from '@/views/ClickAndFinance/modals/SelectClientModal.vue' //
import ResetFormModal from '@/views/ClickAndFinance/modals/ResetFormModal.vue' //
import ContractSavedModal from '@/views/ClickAndFinance/modals/ContractSavedModal.vue' //
import ContractApprovalRequestedModal from '@/views/ClickAndFinance/modals/ContractApprovalRequestedModal.vue' //
import ShowTokenModal from '@/views/Token/ShowTokenModal.vue' //
import ConfirmUnitTypeUpdateModal from '@/views/UnitType/ConfirmUnitTypeUpdateModal.vue' //
import EquipmentConnectivityModal from '@/views/Unit/components/EquipmentConnectivityModal.vue' //
import EquipmentNotesModal from '@/views/Unit/components/EquipmentNotesModal.vue' //
import NewServiceModal from '@/views/Services/NewServiceModal.vue' //
import UploadProtocolsModal from '@/views/Services/UploadProtocolsModal.vue'
import InviteItemModal from '@/views/Users/InviteItemModal.vue' //
import MaintenanceModal from '@/views/Contract/MaintenanceModal.vue' //
import CustomerUidModal from '@/views/Contract/CustomerUidModal.vue' //
import ConfirmActionModal from './ConfirmActionModal.vue'
import CreateItemModal from './CreateItemModal.vue'

export default {
    AuthorizedPersonModal,
    ClientItemModal,
    UnitTypeItemModal,
    // ContractUploadModal,
    UploadContractFileModal,
    InvoiceUploadModal,
    ContractCreateModal,
    SelectUnitTypeModal,
    SelectClientModal,
    ResetFormModal,
    ContractSavedModal,
    ContractApprovalRequestedModal,
    ShowTokenModal,
    ConfirmUnitTypeUpdateModal,
    EquipmentConnectivityModal,
    NewServiceModal,
    UploadProtocolsModal,
    InviteItemModal,
    CustomerUidModal,
    MaintenanceModal,
    ConfirmActionModal,
    CreateItemModal,
    EquipmentNotesModal,
}
