<template>
    <div
        class="core-number-input-wrapper"
        :style="styles"
    >
        <InputNumber
            v-model="value"
            :locale="this.numberFormat()"
            :name="name"
            :inputStyle="{ 'text-align': 'right' }"
            :placeholder="placeholder"
            :class="classes"
            :style="styles"
            :suffix="suffix"
            :disabled="disabled"
            @focus="onFocus()"
            @blur="onBlur()"
        />

        <InputNotification
            v-if="isFocused"
            :validator="validator"
            :customNotificationMessage="customNotificationMessage"
        />
    </div>
</template>

<script>
import InputNumber from 'primevue/inputnumber'
import InputNotification from '@/components/InputNotification'
import { numberFormatPlaceholder } from '@/helpers/localeOptions'

export default {
    name: 'CoreNumberInput',

    components: { InputNumber, InputNotification },

    props: {
        name: {
            type: String,
            default: '',
        },

        suffix: {
            type: String,
            default: null,
        },

        condensed: {
            type: Boolean,
            default: false,
        },

        fullWidth: {
            type: Boolean,
            default: false,
        },

        width: {
            type: Number,
            default: null,
        },

        validator: {
            type: Object,
            default: null,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        customNotificationMessage: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            isFocused: false,
        }
    },

    mounted() {
        if (this.validator) {
            if (this.value === null || this.value === '') {
                this.validator.$reset()
            } else {
                this.validator.$touch()
            }
        }
    },

    computed: {
        value: {
            get() {
                return this.$attrs.value
            },
            set(value) {
                this.$emit('input', value)

                if (this.validator) {
                    this.validator.$touch()
                }
            },
        },

        styles() {
            const stylesObject = {}

            if (this.width !== null && typeof this.width === 'number') {
                stylesObject.width = `${this.width}px`
            }

            return stylesObject
        },

        classes() {
            return {
                'core-number-input': true,
                'core-number-input--full-width': this.fullWidth,
                'core-number-input--condensed': this.condensed,
                'core-valid': this.isValid,
                'core-invalid': this.isInvalid,
                'core-warning': this.isWarning,
            }
        },

        isValid() {
            if (this.validator !== null && !this.validator.$invalid && this.validator.$dirty) {
                return true
            }

            return false
        },

        isInvalid() {
            if (
                this.customNotificationMessage !== null &&
                this.customNotificationMessage.severity !== undefined &&
                this.customNotificationMessage.severity === 'error'
            ) {
                return true
            }

            if (this.validator !== null && this.validator.$invalid && this.validator.$dirty) {
                return true
            }

            return false
        },

        isWarning() {
            if (
                this.customNotificationMessage !== null &&
                this.customNotificationMessage.severity !== undefined &&
                this.customNotificationMessage.severity === 'warn'
            ) {
                return true
            }

            return false
        },

        validatorErrors() {
            if (this.validator.$error === undefined) {
                return {}
            }
            return this.validator.$error
        },

        validatorParams() {
            if (this.validator.$params === undefined) {
                return {}
            }
            return this.validator.$params
        },

        placeholder() {
            const placeholder_tk_name = numberFormatPlaceholder(this.$store.state.user.user.preferred_number_format);
            const currency_symbol = this.currencySymbol

            return this.$t( placeholder_tk_name, { currency_symbol })
        },
    },

    methods: {
        onFocus() {
            this.isFocused = true
            this.$emit('focus')
        },

        onBlur() {
            this.isFocused = false
            this.$emit('blur')
        },
    },
}
</script>

<style lang="scss">
.core-number-input-wrapper {
    position: relative;
}

.core-number-input {
    width: 100%;

    & > .p-inputtext.p-component.p-inputnumber-input {
        width: 100%;
    }

    & .p-inputtext.p-component.p-inputnumber-input::placeholder {
        color: $cs-25;
    }

    &--condensed > .p-inputtext {
        padding: 1.4rem !important;
        font-size: 1.6rem !important;
    }

    &--full-width {
        width: 100%;

        .p-inputtext {
            width: 100%;
        }
    }
}

.core-invalid {
    &.p-inputnumber.p-component,
    &.p-inputnumber.p-component:focus,
    &.p-inputnumber.p-component:hover {
        .p-inputtext {
            border: 1px solid $color-error;
        }
    }
}
.core-warning {
    &.p-inputnumber.p-component,
    &.p-inputnumber.p-component:focus,
    &.p-inputnumber.p-component:hover {
        .p-inputtext {
            border: 1px solid $color-warning;
        }
    }
}
.core-valid {
    &.p-inputnumber.p-component,
    &.p-inputnumber.p-component:focus,
    &.p-inputnumber.p-component:hover {
        .p-inputtext {
            border: 1px solid $cp;
        }
    }
}
</style>
