<template>
    <div class="modal-residual-values-table">
        <DataTable
            :value="table"
            editMode="cell"
            :loading="loading"
            stripedRows
            @cell-edit-complete="onCellEditComplete"
            class="editable-cells-table p-datatable-lg"
            :class="{ 'filter-blur': loading }"
            responsiveLayout="scroll"
        >
            <Column field="months">
                <template #header>
                    <span class="font-bold">
                        {{ $t('§eq_type.months') }}
                    </span>
                </template>
                <template #body="slotProps">
                    <span>
                        {{ slotProps.data.months }}
                    </span>
                </template>
            </Column>

            <template v-if="showLargeTable">
                <Column field="80%">
                    <template #header>
                        <span class="modal-residual-values-table__cell font-bold"> 80% </span>
                    </template>
                    <template #body="slotProps">
                        <span class="modal-residual-values-table__cell">
                            {{ displayAsCurrency(slotProps.data['80%'], 0, currencyCode) }}
                        </span>
                    </template>
                    <template #editor="slotProps">
                        <CoreCurrencyInput
                            v-model="slotProps.data['80%']"
                            class="modal-residual-values-table__currency-input"
                            :maxFractionDigits="0"
                            :showBorder="false"
                            autofocus
                            :currency="currencyCode"
                        />
                    </template>
                </Column>

                <Column field="90%">
                    <template #header>
                        <span class="modal-residual-values-table__cell font-bold"> 90% </span>
                    </template>
                    <template #body="slotProps">
                        <span class="modal-residual-values-table__cell">
                            {{ displayAsCurrency(slotProps.data['90%'], 0, currencyCode) }}
                        </span>
                    </template>
                    <template #editor="slotProps">
                        <CoreCurrencyInput
                            v-model="slotProps.data['90%']"
                            class="modal-residual-values-table__currency-input"
                            :maxFractionDigits="0"
                            :showBorder="false"
                            autofocus
                            :currency="currencyCode"
                        />
                    </template>
                </Column>

                <Column field="100%">
                    <template #header>
                        <span class="modal-residual-values-table__cell font-bold"> 100% </span>
                    </template>
                    <template #body="slotProps">
                        <span class="modal-residual-values-table__cell">
                            {{ displayAsCurrency(slotProps.data['100%'], 0, currencyCode) }}
                        </span>
                    </template>
                    <template #editor="slotProps">
                        <CoreCurrencyInput
                            v-model="slotProps.data['100%']"
                            class="modal-residual-values-table__currency-input"
                            :maxFractionDigits="0"
                            :showBorder="false"
                            autofocus
                            :currency="currencyCode"
                        />
                    </template>
                </Column>

                <Column field="110%">
                    <template #header>
                        <span class="modal-residual-values-table__cell font-bold"> 110% </span>
                    </template>
                    <template #body="slotProps">
                        <span class="modal-residual-values-table__cell">
                            {{ displayAsCurrency(slotProps.data['110%'], 0, currencyCode) }}
                        </span>
                    </template>
                    <template #editor="slotProps">
                        <CoreCurrencyInput
                            v-model="slotProps.data['110%']"
                            class="modal-residual-values-table__currency-input"
                            :maxFractionDigits="0"
                            :showBorder="false"
                            autofocus
                            :currency="currencyCode"
                        />
                    </template>
                </Column>

                <Column field="120%">
                    <template #header>
                        <span class="modal-residual-values-table__cell font-bold"> 120% </span>
                    </template>
                    <template #body="slotProps">
                        <span class="modal-residual-values-table__cell">
                            {{ displayAsCurrency(slotProps.data['120%'], 0, currencyCode) }}
                        </span>
                    </template>
                    <template #editor="slotProps">
                        <CoreCurrencyInput
                            v-model="slotProps.data['120%']"
                            class="modal-residual-values-table__currency-input"
                            :maxFractionDigits="0"
                            :showBorder="false"
                            autofocus
                            :currency="currencyCode"
                        />
                    </template>
                </Column>
            </template>
            <Column
                v-else
                field="100%"
            >
                <template #header>
                    <span class="modal-residual-values-table__cell font-bold">
                        {{ showLargeTable ? '80%' : '100%' }}
                    </span>
                </template>
                <template #body="slotProps">
                    <span class="modal-residual-values-table__cell">
                        {{ displayAsCurrency(slotProps.data['100%'], 0, currencyCode) }}
                    </span>
                </template>
                <template #editor="slotProps">
                    <CoreCurrencyInput
                        v-model="slotProps.data['100%']"
                        class="modal-residual-values-table__currency-input"
                        :maxFractionDigits="0"
                        :showBorder="false"
                        autofocus
                        :currency="currencyCode"
                    />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import CoreCurrencyInput from '@/components/core/CoreCurrencyInput'

export default {
    name: 'ModalResidualValuesTable',

    components: { DataTable, Column, CoreCurrencyInput },

    data() {
        return {
            table: [],
            loading: false,
        }
    },

    computed: {
        currencyCode() {
            return this.$store.state.unitType.inputs.currency_code || 'EUR';
        },
        showLargeTable() {
            return this.table[0] && this.table[0]['80%'] !== undefined
        },
    },

    watch: {
        table: {
            handler(newValue) {
                const tableWithoutMonths = newValue.map((monthsObject) => {
                    const { months, ...rest } = monthsObject
                    return rest
                })

                const rvt = {}

                tableWithoutMonths.forEach((monthsObject, index) => {
                    Object.keys(monthsObject).forEach((key) => {
                        const numberKey = Number(key.slice(0, -1)) / 100

                        const laravelFriendlyKey = numberKey.toString().replace('.', '-')

                        if (rvt[laravelFriendlyKey] === undefined) {
                            rvt[laravelFriendlyKey] = {}
                        }
                        rvt[laravelFriendlyKey][index * 12] = tableWithoutMonths[index][key]
                    })
                })

                this.$emit('rvt', rvt)
            },
            deep: true,
            immediate: true,
        },
    },

    mounted() {
        this.getRvt()
    },

    destroyed() {
        this.$emit('rvt', null)
    },

    methods: {
        setInput({ name, value }) {
            this.$store.commit('unitType/SET_INPUT', { name, value })
        },

        addMonthsColumn(rvt) {
            const MONTHS_IN_YEAR = 12

            return rvt.map((rowObject, index) => {
                const rowWithMonthsColumn = {
                    months: index * MONTHS_IN_YEAR,
                    ...rowObject,
                }
                return rowWithMonthsColumn
            })
        },

        async getRvt() {
            this.loading = true
            const routeName = await this.route('unit_type.get_residual_values_table')
            const rvt = await this.$store.dispatch('unitType/calculateRvt', {
                routeName,
                openModal: false,
            })

            this.loading = false

            const tableWithoutMonthsColumn = this.getTableData(rvt)

            this.table = this.addMonthsColumn(tableWithoutMonthsColumn)
        },

        getTableData(rvt) {
            return Object.keys(rvt).reduce((tableArray, utilization) => {
                Object.keys(rvt[utilization]).map((months, index) => {
                    if (tableArray[index] === undefined) {
                        tableArray[index] = {}
                    }
                    tableArray[index][`${Math.round(utilization * 100)}%`] = Math.round(
                        rvt[utilization][months]
                    )
                })
                return tableArray
            }, [])
        },

        onCellEditComplete(event) {
            const { data, newValue, field } = event

            if (newValue >= 0) data[field] = newValue
            else event.preventDefault()
        },
    },
}
</script>

<style lang="scss">
.modal-residual-values-table {
    &
        .p-datatable.p-datatable-lg
        .p-datatable-tbody
        > tr
        > td:has(.modal-residual-values-table__cell) {
        width: 14.5rem;
        text-align: right;
        padding: 1.6rem 1.25rem;
        cursor: pointer;
    }

    &
        .p-datatable.p-datatable-lg
        .p-datatable-thead
        > tr
        > th:has(.modal-residual-values-table__cell) {
        text-align: right;
    }

    .p-datatable th .p-column-header-content:has(.modal-residual-values-table__cell) {
        display: block;
    }

    &__currency-input {
        & > input {
            padding: 1.6rem 0;
            width: 12rem;
        }
    }

    .p-datatable-loading-overlay {
        position: absolute;
        inset: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($cn-10, 0.7);
        z-index: 2;
    }

    .p-datatable-loading-icon {
        font-size: 2.4rem;
        color: rgba($cs, 0.7);
    }

    .filter-blur {
        filter: blur(3px);
    }
}
</style>
