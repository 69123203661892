export const LOCALE_NAMES = {
    enGB: 'en-GB',
    enIE: 'en-IE',
    enUS: 'en-US',
    deDE: 'de-DE',
}

export const localeOptions = (pdfLanguages = false) => {
    const localeObjects = [
        { value: LOCALE_NAMES.enGB, label: '§user.locale_option_en_GB' },
        { value: LOCALE_NAMES.enIE, label: '§user.locale_option_en_IE' },
        { value: LOCALE_NAMES.deDE, label: '§user.locale_option_de_DE' },
    ]

    if (!pdfLanguages) {
        localeObjects.push({ value: LOCALE_NAMES.enUS, label: '§user.locale_option_en_US' })
    }

    return localeObjects
}

export const dateFormatOptions = () => {
    return [
        { value: LOCALE_NAMES.enGB, label: '§user.date_format_option_en_GB' },
        { value: LOCALE_NAMES.enIE, label: '§user.date_format_option_en_IE' },
        { value: LOCALE_NAMES.deDE, label: '§user.date_format_option_de_DE' },
        { value: LOCALE_NAMES.enUS, label: '§user.date_format_option_en_US' },
    ]
}

export const numberFormatOptions = (vm, currency_symbol = '€') => {
    return [
        { value: LOCALE_NAMES.enGB, label: vm.$t('§user.number_format_option_en_GB', { currency_symbol }) },
        { value: LOCALE_NAMES.enIE, label: vm.$t('§user.number_format_option_en_IE', { currency_symbol }) },
        { value: LOCALE_NAMES.deDE, label: vm.$t('§user.number_format_option_de_DE', { currency_symbol }) },
        { value: LOCALE_NAMES.enUS, label: vm.$t('§user.number_format_option_en_US', { currency_symbol }) },
    ]
}

export const numberFormatPlaceholder = (locale) => {
    switch (locale) {
        case LOCALE_NAMES.enGB:
            return '§ui.placeholder_input_number_en_GB';
        case LOCALE_NAMES.enIE:
            return '§ui.placeholder_input_number_en_IE';
        case LOCALE_NAMES.deDE:
            return '§ui.placeholder_input_number_de_DE';
        case LOCALE_NAMES.enUS:
            return '§ui.placeholder_input_number_en_US';
        default:
            return '§ui.placeholder_input_number_en_GB';
    }
};