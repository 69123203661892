import { isValid, addMonths, startOfMonth } from 'date-fns'
import { apiPost, apiGet, apiDownload, handleError, refreshError } from '@/helpers/api'

const namespaced = true

const CALCULATION_SEND_DATA = [
    'amount',
    'begin',
    'client_select',
    'consumable_cost',
    'discount',
    'duration',
    'expected_utilization',
    'billing_unit',
    'extra_price',
    'down_payment',
    'installation_fee',
    'maintenance',
    'maintenance_cost_show_as',
    'consumables_cost_show_as',
    'net_list_price',
    'currency_code',
    'payment_per_piece_decimals',
    'residual_value',
    'revenue_per_wash',
    'time_fee_ratio',
    'transportation',
    'unit_country_id',
    'unit_type',
]

const DEFAULT_STATE_INPUTS = {
    // price section
    unit_type: null,
    unit_type_name: '',
    unit_type_max_usage: null,
    unit_type_planned_usage: null,
    unit_type_billing_units: null,
    net_list_price: null,
    currency_code: null,
    down_payment: null,
    discount: null,
    extra_price: null,
    amount: 0,

    // contract info section
    expected_utilization: null,
    billing_unit: null,
    unit_country_id: '',
    duration: null,
    buy_back: null,
    residual_value: 0,
    stored_residual_value: 0,
    client_select: null,
    client_select_name: '',
    client_country_id: null,

    // ppu model section
    time_fee_ratio: null,
    pay_per_use_fee: null,
    payment_per_piece_decimals: 2,

    // additional costs section
    transportation: null,
    installation_fee: null,
    maintenance: null,
    consumable_cost: null,
    revenue_per_wash: null,
    maintenance_cost_show_as: null,
    consumables_cost_show_as: null,

    begin: '',
}

const MONTHS_IN_YEAR = 12

const DEFAULT_STATE_CALCULATION = {
    display_price_per_h: 0,
    time_fee_per_m: 0,
}

export const FORM_STATUS = {
    incomplete: 'incomplete',
    valid: 'valid',
    calculated: 'calculated',
}

const state = () => ({
    inputs: { ...DEFAULT_STATE_INPUTS },

    formStatus: FORM_STATUS.incomplete,

    calculation: { ...DEFAULT_STATE_CALCULATION },

    sectionValidity: {
        isAmountValid: false,
        isExpectedUtilizationValid: false,
        isCountryValid: false,
        isDurationValid: false,
        isBuyBackValid: false,
        isResidualValueValid: true,
        isPpuModelValid: false,
    },

    additionalFormErrors: [],

    calculate: 0,

    contractId: null,
})

export const mutations = {
    SET_FORM_STATUS(state, { status }) {
        state.formStatus = status
    },

    SET_INPUT(state, { name, value }) {
        state.inputs[name] = value

        state.calculate += 1
    },

    RESET_FORM(state) {
        state.inputs = { ...DEFAULT_STATE_INPUTS }
        state.calculation = { ...DEFAULT_STATE_CALCULATION }
        state.formStatus = FORM_STATUS.incomplete
        state.sectionValidity = {
            isAmountValid: false,
            isExpectedUtilizationValid: false,
            isCountryValid: false,
            isDurationValid: false,
            isBuyBackValid: false,
            isResidualValueValid: true,
            isPpuModelValid: false,
        }
        state.contractId = null
        state.additionalFormErrors = []
    },

    SET_SECTION_VALIDITY(state, { name, value }) {
        state.sectionValidity[name] = value
    },

    HANDLE_ADDITIONAL_FORM_ERRORS(state, { error, remove }) {
        if (remove) {
            state.additionalFormErrors = state.additionalFormErrors.filter(
                (el) => el.label !== error.label
            )
        } else if (!state.additionalFormErrors.map((el) => el.label).includes(error.label)) {
            state.additionalFormErrors = [...state.additionalFormErrors, error]
        }
    },

    RESET_CALCULATE(state) {
        state.calculate = 0
    },

    SET_CALCULATED_VALUES(state, calculation) {
        state.calculation = { ...calculation }
    },

    SET_CONTRACT_ID(state, { id }) {
        state.contractId = id
    },
}

export const actions = {
    async getItemsList({ dispatch }, { vm, route }) {
        try {
            dispatch('showLoader', null, { root: true })
            const list = await apiGet(route)
            dispatch('hideLoader', null, { root: true })

            return list
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async matchCalculate({ dispatch, state, commit }, { vm }) {
        try {
            const cleanedInputs = CALCULATION_SEND_DATA.reduce((holder, key) => {
                holder[key] = state.inputs[key] === null ? 0 : state.inputs[key]

                if (key === 'unit_type') holder[key] = state.inputs[key]
                if (key === 'client_select') holder[key] = state.inputs[key]
                if (key === 'duration') holder[key] = state.inputs[key] * MONTHS_IN_YEAR
                if (key === 'residual_value') holder[key] = Number(state.inputs[key])
                if (key === 'begin' && !isValid(holder['begin'])) {
                    const todayNextMonth = addMonths(new Date(), 1)
                    const date = startOfMonth(todayNextMonth)
                    commit('SET_INPUT', {
                        name: 'begin',
                        value: date,
                    })
                    holder['begin'] = date
                }

                return holder
            }, {})
            const calculation = await apiPost(vm.route('click_finance.calculate'), cleanedInputs)

            return calculation
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async getResidualValue({ state, dispatch }, { vm }) {
        try {
            const { duration, expected_utilization, unit_type } = state.inputs

            const requestData = {
                months: duration * MONTHS_IN_YEAR,
                annual_utilization: expected_utilization,
            }

            const response = await apiPost(
                vm.route('unit_type.residual_value', unit_type),
                requestData
            )

            return response
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async saveContract({ dispatch, commit, state }, { vm }) {
        try {
            const response = await apiPost(vm.route('click_finance.store'), {
                ...state.inputs,
            })

            commit('SET_CONTRACT_ID', { id: response.id })
            return response
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async saveAndApproveContract({ dispatch, commit, state }, { vm }) {
        try {
            commit(
                'modal/SET_OPEN',
                {
                    name: 'ContractApprovalRequestedModal',
                    size: 'small',
                    title: '§caf.contract_saved_and_approved_modal_title',
                    iconName: 'check',
                    data: {
                        contractId: '414',
                    },
                },
                { root: true }
            )
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async downloadPdf({ dispatch, state, rootState }, payload) {
        try {
            dispatch('showLoader', null, { root: true })
            const filename = 'Pay-per-Use Pricing'
            let contract = state.contractId

            if (contract === null) {
                const response = await dispatch('saveContract', {
                    vm: payload.vm,
                })

                contract = response.id
            }

            const locale =
                rootState.contract.calculationPdfLanguage === ''
                    ? rootState.user.user.preferred_locale
                    : rootState.contract.calculationPdfLanguage

            await apiDownload(
                payload.vm.route('click_finance.pdf', {
                    locale,
                    contract,
                }),
                filename
            )
            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            handleError(dispatch, error)
        }
    },
}

const getters = {}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
}
