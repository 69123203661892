<template>
    <div>
        <CoreDropdown
            v-model="relation"
            :options="typeOptions"
            :placeholder="$t('§ui.placeholder_select_file_type')"
            :disabled="!isOptionalFileType"
            class="mb-5"
        />

        <CoreTwoColumnsRow
            v-if="isTakeoverProtocolType"
            class="mb-5"
        >
            <template #left>
                <CoreBorderWrapper class="flex-grow-1 flex-shrink-1 flex-basis-50">
                    {{ $t('§contracts.takeover_date') }}
                </CoreBorderWrapper>
            </template>
            <template #right>
                <CoreDateInput
                    v-model="begin"
                    class="flex-grow-1 flex-shrink-1 flex-basis-50"
                />
            </template>
        </CoreTwoColumnsRow>

        <DragAndDropUpload
            :fileName="displayFileName"
            :fileId="fileId"
            :error="error"
            :disabled="isUploadDisabled"
            @delete-file="removeUppyFiles"
            class="mb-5"
        />

        <CoreTwoColumnsRow
            v-if="isExpiryDateInputShown"
            class="mb-5"
        >
            <template #left>
                <CoreBorderWrapper class="flex-grow-1 flex-shrink-1 flex-basis-50">
                    {{ $t('§contracts.expiry_date') }}
                </CoreBorderWrapper>
            </template>
            <template #right>
                <CoreDateInput
                    v-model="expiry_date"
                    class="flex-grow-1 flex-shrink-1 flex-basis-50"
                />
            </template>
        </CoreTwoColumnsRow>

        <div class="upload-contract-file-modal__buttons">
            <CoreButtonPrimary
                :disabled="isUploadButtonDisabled"
                label="§ui_button.upload"
                icon="pi-upload"
                :loading="saving"
                @click="handleUpload"
            />

            <CoreButtonSecondary
                label="§ui_button.cancel"
                @click="handleClose"
                :disabled="saving"
            />
        </div>
    </div>
</template>

<script>
import { parseISO, isValid, format } from 'date-fns'
import uppyInstance from '@/helpers/uppyInstance.js'
import CoreBorderWrapper from '@/components/core/CoreBorderWrapper'
import CoreTwoColumnsRow from '@/components/core/CoreTwoColumnsRow'
import CoreDropdown from '@/components/core/CoreDropdown.vue'
import CoreDateInput from '@/components/core/CoreDateInput.vue'
import CoreButtonPrimary from '@/components/core/CoreButtonPrimary.vue'
import CoreButtonSecondary from '@/components/core/CoreButtonSecondary.vue'
import DragAndDropUpload from '@/components/DragAndDropUpload'

export default {
    name: 'UploadContractFileModal',

    components: {
        CoreBorderWrapper,
        CoreTwoColumnsRow,
        CoreDropdown,
        CoreDateInput,
        CoreButtonPrimary,
        CoreButtonSecondary,
        DragAndDropUpload,
    },

    data() {
        return {
            saving: false,
            fileId: null,
            uppyFileName: '',
            error: '',
            relation: '',
            isFileSelected: false,
            begin: null,
            expiry_date: null,
        }
    },

    computed: {
        uppy() {
            return uppyInstance
        },

        isOptionalFileType() {
            return !this.$store.state.modal.data.group
        },

        typeOptions() {
            const fileNames = this.isOptionalFileType
                ? [...this.$store.state.contractFiles.optionalTypes]
                : [
                      ...Object.keys(this.$store.state.contractFiles.requiredFiles),
                      ...Object.keys(this.$store.state.contractFiles.insuranceFiles),
                  ]

            return fileNames.map((name) => ({
                label: this.$t(`§contracts.file_type_${name}`),
                value: name,
            }))
        },

        isUploadDisabled() {
            if (this.relation && this.relation.length > 0) {
                return false
            }

            return true
        },

        displayFileName() {
            if (this.uppyFileName !== '') {
                return this.uppyFileName
            }

            return ''
        },

        isTakeoverProtocolType() {
            return this.relation === 'takeover_protocol'
        },

        isUploadButtonDisabled() {
            if (this.isTakeoverProtocolType) {
                return !this.isFileSelected || !this.begin
            }

            if (this.isExpiryDateInputShown) {
                return !this.isFileSelected || !this.expiry_date
            }

            return !this.isFileSelected
        },

        isExpiryDateInputShown() {
            const TYPES_WITH_EXPIRY_DATE_INPUT = [
                'insurance_certificate_general_insurance',
                'insurance_certificate_machine_breakdown',
            ]

            return TYPES_WITH_EXPIRY_DATE_INPUT.includes(this.relation)
        },
    },

    created() {
        this.uppy.on('file-added', this.handleFileAdded)
        this.uppy.on('file-removed', this.handleFileRemoved)
        this.uppy.on('upload-success', this.handleSuccess)

        this.uppy.setOptions({
            restrictions: {
                maxNumberOfFiles: 1,
                allowedFileTypes: ['.jpg', '.jpeg', '.png', '.pdf', '.xls', '.xlsx'],
            },
        })
    },

    mounted() {
        if (this.$store.state.modal.data.relation !== undefined) {
            this.relation = this.$store.state.modal.data.relation
        }

        if (this.isTakeoverProtocolType) {
            const beginDateObject = parseISO(this.$store.state.contract.contractData.begin)
            if (isValid(beginDateObject)) {
                this.begin = beginDateObject
            }
        }
    },

    beforeDestroy() {
        this.uppy.off('file-added')
        this.uppy.off('file-removed')
        this.uppy.off('upload-success')

        this.removeUppyFiles()
        this.uppy.close({ reason: 'unmount' })
    },

    methods: {
        handleFileAdded(file) {
            this.fileId = file.id
            this.uppyFileName = file.name
            this.isFileSelected = true
        },

        handleFileRemoved() {
            this.fileId = null
            this.uppyFileName = ''
            this.isFileSelected = false
        },

        handleClose() {
            this.$store.commit('modal/SET_CLOSE')
        },

        handleUpload() {
            this.saving = true
            this.isFileSelected = false

            this.uppy.setMeta({
                relation: this.relation,
            })

            if (this.isTakeoverProtocolType) {
                this.uppy.setMeta({
                    begin: format(this.begin, 'Y-MM-dd'),
                })
            }

            if (this.isExpiryDateInputShown) {
                this.uppy.setMeta({
                    expiry_date: format(this.expiry_date, 'Y-MM-dd'),
                })
            }

            this.$store.dispatch('contractFiles/upload', {
                vm: this,
                contractId: this.$store.state.modal.data.contractId,
                group: this.$store.state.modal.data.group,
                refreshContract: this.$store.state.modal.data.refreshContract,
                refreshMissingContractFiles:
                    this.$store.state.modal.data.refreshMissingContractFiles,
                uppy: this.uppy,
            })
        },

        handleSuccess(file, response) {
            this.saving = false
            this.isFileSelected = false
            if (response.status === 200) {
                this.handleClose()
            }
        },

        removeUppyFiles() {
            const addedFiles = this.uppy.getFiles()
            addedFiles.forEach((file) => this.uppy.removeFile(file.id))
            this.uppyFileName = ''
            this.fileId = null
        },
    },
}
</script>

<style lang="scss" scoped>
.upload-contract-file-modal__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}
</style>
