<template>
    <div class="currency-percentage-input-wrapper">
        <CoreTwoColumnsRow
            :showBorder="true"
            :error="isError"
            :class="classes"
        >
            <template #left>
                <CorePercentageInput
                    v-model="percentage"
                    :min="0"
                    :max="maxPercent"
                    :inline="true"
                    :maxFractionDigits="2"
                    :minFractionDigits="1"
                    :step="0.5"
                    :showButtons="true"
                />
            </template>
            <template #right>
                <CoreCurrencyInput
                    v-model="value"
                    :showBorder="false"
                    :currency="currency"
                    :maxFractionDigits="currencyDigits"
                    @focus="() => (isFocused = true)"
                    @blur="() => (isFocused = false)"
                />
            </template>
        </CoreTwoColumnsRow>

        <InputNotification
            v-if="isFocused"
            :customNotificationMessage="customNotificationMessage"
        />
    </div>
</template>

<script>
import CoreTwoColumnsRow from '@/components/core/CoreTwoColumnsRow'
import CoreCurrencyInput from '@/components/core/CoreCurrencyInput'
import CorePercentageInput from '@/components/core/CorePercentageInput'
import InputNotification from '@/components/InputNotification'
import InputNumber from "primevue/inputnumber";

export default {
    name: 'CurrencyPercentageInput',

    components: {
        InputNumber,
        CoreTwoColumnsRow,
        CoreCurrencyInput,
        CorePercentageInput,
        InputNotification,
    },

    props: {
        baseValue: {
            type: Number,
            required: true,
        },

        maxPercent: {
            type: Number,
            default: 100,
        },

        customNotificationMessage: {
            type: Object,
            default: null,
        },

        currency: {
            type: String,
            default: null,
        },

        currencyDigits: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            isFocused: false,
        }
    },

    computed: {
        value: {
            get() {
                return this.$attrs.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },

        percentage: {
            get() {
                if (this.baseValue === 0) return Number(0)

                const value = (100 * this.$attrs.value) / this.baseValue
                return value <= this.maxPercent ? Number(value.toFixed(2)) : Number(this.maxPercent)
            },
            set(percent) {
                // this.$attrs.value = (this.baseValue * percent) / 100
                this.$emit('input', (this.baseValue * percent) / 100)
            },
        },

        classes() {
            return {
                'currency-percentage-input': true,
            }
        },

        isError() {
            if (
                this.customNotificationMessage !== null &&
                this.customNotificationMessage.hasOwnProperty('severity') &&
                this.customNotificationMessage.severity === 'error'
            ) {
                return true
            }

            return false
        },
    },
}
</script>

<style lang="scss">
.currency-percentage-input-wrapper {
    position: relative;
}

.currency-percentage-input {
    height: 7.1rem;
}
</style>
