import XHRUpload from '@uppy/xhr-upload'
import { apiGet, apiDelete, apiDownload, handleError } from '@/helpers/api'

const namespaced = true

const fileTypes = {
    required: [
        'dnb_report',
        'equipment_purchase_invoice',
        'final_pricing_file',
        'transaction_memo',
        'leasing_application_signed',
        'handover_protocol',
        'takeover_protocol',
        'machine_photo',
    ],
    insurance: [
        'insurance_policy_general_insurance',
        'insurance_certificate_general_insurance',
        'insurance_policy_machine_breakdown',
        'insurance_certificate_machine_breakdown',
    ],
}

const createFileState = (fileTypes) => {
    return fileTypes.reduce((acc, type) => {
        acc[type] = []
        return acc
    }, {})
}

const state = () => ({
    requiredFiles: createFileState(fileTypes.required),
    insuranceFiles: createFileState(fileTypes.insurance),

    optionalTypes: [
        'maintenance_contract',
        'dnb_report_guarantor',
        'inter_company_guarantee',
        'historical_usage_data',
    ],

    optionalFilesTableRefreshKey: 0,
})

export const mutations = {
    UPDATE_LIST(state, { group, listName, list }) {
        if (!group) {
            state.optionalFiles = [...list]
        } else {
            state[group][listName] = [...list]
        }
    },

    ADD_FILE(state, { group, listName, file }) {
        state[group][listName].unshift(file)
    },

    REMOVE_FILE_BY_ID(state, { id }) {},

    REFRESH_OPTIONAL_FILES_TABLE(state) {
        state.optionalFilesTableRefreshKey += 1
    },

    CLEAR_STATE(state) {
        state.requiredFiles = createFileState(fileTypes.required)
        state.insuranceFiles = createFileState(fileTypes.insurance)
        state.optionalFilesTableRefreshKey = 0
    },
}

export const actions = {
    requiredFilesList({ state, commit }, { required }) {
        required.forEach((file) => {
            const group = fileTypes.insurance.includes(file.type)
                ? 'insuranceFiles'
                : 'requiredFiles'
            const list = [...state[group][file.type], file]
            commit('UPDATE_LIST', { group, listName: file.type, list })
        })
    },

    optionalFilesList({ commit }, { optional }) {
        commit('UPDATE_LIST', { list: optional })
    },

    async fetchFilesList({ dispatch }, { vm, contract, type }) {
        try {
            const list = await apiGet(vm.route(`contract.files.${type}`, { contract }))
            dispatch(`${type}FilesList`, list)
        } catch (error) {
            console.log(error)
        }
    },

    async download({ dispatch }, { vm, fileId, filename }) {
        try {
            dispatch('showLoader', null, { root: true })
            await apiDownload(vm.route('contract.download', { contract_file: fileId }), filename)
            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async delete({ dispatch, commit, state }, { vm, propsToPass }) {
        try {
            dispatch('showLoader', null, { root: true })

            const { file, group, type } = propsToPass
            await apiDelete(vm.route('contract.files.destroy', { file }))

            if (group) {
                const listCopy = state[group][type].filter((fileInList) => fileInList.id !== file)
                commit('UPDATE_LIST', { group, listName: type, list: listCopy })
            } else {
                commit('REFRESH_OPTIONAL_FILES_TABLE')
            }

            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    upload(
        { dispatch, state, commit },
        { vm, uppy, contractId, group, refreshContract, refreshMissingContractFiles }
    ) {
        const headers = {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        }

        uppy.use(XHRUpload, {
            endpoint: vm.route('contract.upload', {
                contract: contractId,
            }),
            headers,
            formData: true,
            fieldName: 'file',
            bundle: true,
            allowedMetaFields: null,
        })

        uppy.upload()

        uppy.on('upload-success', (file, response) => {
            dispatch('hideLoader', null, { root: true })

            const storedFile = response.body.file

            if (refreshContract) {
                vm.$router.go()
            } else if (refreshMissingContractFiles) {
                commit('REFRESH_OPTIONAL_FILES_TABLE')
            } else if (group) {
                commit('ADD_FILE', {
                    group,
                    listName: storedFile.type,
                    file: storedFile,
                })
            } else {
                commit('REFRESH_OPTIONAL_FILES_TABLE')
            }
        })

        uppy.on('error', (e) => {
            dispatch('hideLoader', null, {
                root: true,
            })
            handleError(dispatch, e)
        })
    },
}

const getters = {}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
}
