<template>
    <div class="form">
        <CoreTwoColumnsRow class="contract-data-item-row">
            <template #left>
                <div
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    :condensed="true"
                >
                    <span>
                        {{ $t('§contracts.as_of') }}
                    </span>
                </div>
            </template>
            <template #right>
                <CoreTwoColumnsRow :style="{ padding: 0 }">
                    <template #left>
                        <div
                            class="flex-grow-1 flex-shrink-1 flex-basis-45"
                            :condensed="true"
                        >
                        <span>{{ $t(props.valueColumnHeader) }}</span>
                        </div>
                    </template>
                    <template #right>
                        <div
                            class="flex-grow-1 flex-shrink-1 flex-basis-60"
                            :condensed="true"
                        >
                            <CoreTwoColumnsRow :style="{ padding: 0 }">
                            <template #left>
                            <span>
                                {{ $t('§contracts.percent_change') }}
                            </span>
                            </template>
                            <template #right>
                                &nbsp;
                            </template>
                            </CoreTwoColumnsRow>
                        </div>
                    </template>
                </CoreTwoColumnsRow>
            </template>
        </CoreTwoColumnsRow>
        <CoreTwoColumnsRow 
            v-for="(item, as_of) in props.history"
            class="contract-data-item-row"
        >
            <template #left>
                <div
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    :condensed="true"
                >
                    <span>
                        {{ displayAsDate(parseISO_(item.as_of)) }}
                    </span>
                </div>
            </template>
            <template #right>
                <CoreTwoColumnsRow :style="{ padding: 0 }">
                    <template #left>
                        <div
                            class="flex-grow-1 flex-shrink-1 flex-basis-45"
                            :condensed="true"
                        >
                            <span>
                                {{ displayAsCurrency(item.value, 2) }}
                            </span>
                        </div>
                    </template>
                    <template #right>
                        <div
                            class="flex-grow-1 flex-shrink-1 flex-basis-60"
                            :condensed="true"
                        >
                            <CoreTwoColumnsRow :style="{ padding: 0 }">
                            <template #left>
                                <span>
                                    {{ displayAsPercent(item.change) }}
                                </span>
                            </template>
                            <template #right>
                                <CoreButtonSecondary
                                    v-if="hasPermission('admin')"
                                    label="§ui_button.delete"
                                    icon="pi-exclamation-circle"
                                    @click="handleDelete(item.id)"
                                    :danger="true"
                                />
                                <span v-else>
                                    &nbsp;
                                </span>
                            </template>
                            </CoreTwoColumnsRow>
                        </div>
                    </template>
                </CoreTwoColumnsRow>
            </template>
        </CoreTwoColumnsRow>
        <CoreTwoColumnsRow
            v-if="hasPermission('admin')"
            class="contract-data-item-row"
        >
            <template #left>
                <div
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                >
                    <CoreDateInput
                        v-if="showForm"
                        v-model="inputs.as_of"
                        class="flex-grow-1 flex-shrink-1 flex-basis-50"
                        placeholder="§ui.placeholder_tcf_cost"
                    />
                    <CoreButtonPrimary
                        v-else
                        label="§ui_button.add_new"
                        icon="pi-plus"
                        @click="showForm=true"
                        :colorPrimary="true"
                    />
                </div>
            </template>
            <template #right>
                <CoreTwoColumnsRow :style="{ padding: 0 }">
                    <template #left>
                        <div
                            class="flex-grow-1 flex-shrink-1 flex-basis-65"
                        >
                            <CurrencyPercentageInput
                                v-if="showForm"
                                :baseValue="props.current_value"
                                :currencyDigits="2"
                                :maxPercent="200"
                                v-model="inputs.value"
                                class="flex-grow-1 flex-shrink-1 flex-basis-65"
                            />
                        </div>
                    </template>
                    <template #right>
                        <div
                            class="flex-grow-1 flex-shrink-1 flex-basis-35"
                        >
                            <CoreButtonPrimary
                                v-if="showForm"
                                :disabled="!isValid"
                                @click="handleSave()"
                                label="§ui_button.save"
                            />
                        </div>
                    </template>
                </CoreTwoColumnsRow>
            </template>
        </CoreTwoColumnsRow>
    </div>
</template>

<script>
import { parseISO, format } from 'date-fns'
import { mapActions } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import CoreTwoColumnsRow from '@/components/core/CoreTwoColumnsRow.vue'
import CoreDateInput from '@/components/core/CoreDateInput'
import CoreCurrencyInput from '@/components/core/CoreCurrencyInput'
import CoreButtonPrimary from '@/components/core/CoreButtonPrimary.vue'
import CoreButtonSecondary from '@/components/core/CoreButtonSecondary.vue'
import CurrencyPercentageInput from '@/views/ClickAndFinance/components/CurrencyPercentageInput'

export default {
    name: 'MaintenanceModal',
    
    components: {
        CoreTwoColumnsRow,
        CoreDateInput,
        CoreCurrencyInput,
        CoreButtonPrimary,
        CoreButtonSecondary,
        CurrencyPercentageInput,
    },
    
    data() {
        return {
            inputs: {
                value: this.$store.state.modal.data.current_value,
                as_of: null,
            },
            showForm: false,
            v$: {},
            props: this.$store.state.modal.data,
        }
    },

    computed: {
        isValid() {
            return !this.v$.$invalid
        },

        rules() {
            return {
                as_of: {
                    required,
                },
                value: {
                    required,
                },
            }
        },
        
        contractData() {
            return this.$store.state.contract.contractData
        },
    },

    created() {
    },

    mounted() {
        this.v$ = useVuelidate(this.rules, this.inputs)
    },

    methods: {
        ...mapActions({
            addTemporaryContractFigure: 'contract/addTemporaryContractFigure',
            deleteTemporaryContractFigure: 'contract/deleteTemporaryContractFigure',
        }),

        handleSave() {
            this.addTemporaryContractFigure({
                vm: this,
                formData: {
                    value: this.inputs.value,
                    as_of: format(this.inputs.as_of, 'Y-M-d'),
                    type: this.props.tcfType,
                },
            })
        },
        handleDelete(id) {
            this.deleteTemporaryContractFigure({
                vm: this,
                tcfId: id,
            })
        },

        parseISO_(s) {
            return parseISO(s)
        },
    },
}
</script>

<style lang="scss" scoped>
.form {
    padding: 40px;

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 40px;

        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;

            color: rgba($vc-primary, 0.7);
        }

        .close {
            position: relative;
            width: 20px;
            height: 20px;

            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                height: 20px;
                width: 2px;
                background: rgba($vc-primary, 0.7);

                transform: rotate(45deg);
            }
            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                height: 20px;
                width: 2px;
                background: rgba($vc-primary, 0.7);

                transform: rotate(-45deg);
            }
        }
    }

    &__buttons {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-top: 50px;
    }
}
</style>

